<template>

    <base-card-plain>
            <template #header>
                <div class="col-3 col-md-3 col-lg-3 col-sm-12 mb-1">
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Search .."
                         aria-describedby="button-addon2" />

                    </div>
                </div>
                <base-card-drop-down>
                    <template #title>
                        Filter subscriptions
                    </template>
                    <template #default>
                        <a class="dropdown-item" href="#">
                            <i class="bi bi-check-circle bs-icon-tiny" ></i>
                            All
                        </a>
                        <a class="dropdown-item" href="#">
                            <i class="bi bi-circle bs-icon-tiny" ></i>
                            Current year
                        </a>
                        <a class="dropdown-item" href="#">
                            <i class="bi bi-circle bs-icon-tiny" ></i>
                            Last 5 years
                        </a>
                        <a class="dropdown-item" href="#">
                            <i class="bi bi-circle bs-icon-tiny" ></i>
                            Last 10 years
                        </a>
                    </template>
                </base-card-drop-down>
            </template>
            <template #default>
                <table class="table table-bordered table-responsive">
                    <thead>
                        <tr>
                            <th >Subscription item(s)</th>
                            <th class="qtr-td-col">date</th>
                            <th class="tiny-td-col">Actions</th>
                        </tr>
                    </thead>
                    <tbody class="">
                        <tr class="table-hover">
                            <td>
                                <span class="fw-bold">Basic</span>
                            </td>
                            <td>
                                <base-badge  badgeColor="secondary">31st July 2020</base-badge>
                            </td>
                            <td>
                                <base-card-drop-down>
                                    <template #title>
                                        <i class="bi bi-three-dots bs-icon-small" ></i>
                                    </template>
                                    <template #default>
                                        <router-link to="/recruitment_manage_applicants" class="dropdown-item">
                                            <i class="bi bi-person-badge-fill bs-icon-small" ></i>
                                            <span> View further details </span>
                                        </router-link>
                                    </template>
                                </base-card-drop-down>
                            </td>
                        </tr>
                        <tr class="table-hover">
                            <td>
                                <span class="fw-bold">Advanced</span>
                            </td>
                            <td>
                                <base-badge badgeColor="secondary">31st August 2020</base-badge>
                            </td>
                            <td>
                                <base-card-drop-down>
                                    <template #title>
                                        <i class="bi bi-three-dots bs-icon-small" ></i>
                                    </template>
                                    <template #default>
                                        <router-link to="/recruitment_manage_applicants" class="dropdown-item">
                                            <i class="bi bi-person-badge-fill bs-icon-small" ></i>
                                            <span> View further details </span>
                                        </router-link>
                                    </template>
                                </base-card-drop-down>
                            </td>
                        </tr>
                        <tr class="table-hover">
                            <td>
                                <span class="fw-bold">Basic</span>
                            </td>
                            <td>
                                <base-badge badgeColor="secondary">31st December 2020</base-badge>
                            </td>
                            <td>
                                <base-card-drop-down>
                                    <template #title>
                                        <i class="bi bi-three-dots bs-icon-small" ></i>
                                    </template>
                                    <template #default>
                                        <router-link to="/recruitment_manage_applicants" class="dropdown-item">
                                            <i class="bi bi-person-badge-fill bs-icon-small" ></i>
                                            <span> View further details </span>
                                        </router-link>
                                    </template>
                                </base-card-drop-down>
                            </td>
                        </tr>
                        <tr class="table-hover">
                            <td>
                                <span class="fw-bold">Enterprise</span>
                            </td>
                            <td>
                                <base-badge badgeColor="secondary">15th June 2020</base-badge>
                            </td>
                            <td>
                                <base-card-drop-down>
                                    <template #title>
                                        <i class="bi bi-three-dots bs-icon-small" ></i>
                                    </template>
                                    <template #default>
                                        <router-link to="/recruitment_manage_applicants" class="dropdown-item">
                                            <i class="bi bi-person-badge-fill bs-icon-small" ></i>
                                            <span> View further details </span>
                                        </router-link>
                                    </template>
                                </base-card-drop-down>
                            </td>
                        </tr>
                        <tr class="table-hover">
                            <td>
                                <span class="fw-bold">Basic</span>
                            </td>
                            <td>
                                <base-badge badgeColor="secondary">31st July 2020</base-badge>
                            </td>
                            <td>
                                <base-card-drop-down>
                                    <template #title>
                                        <i class="bi bi-three-dots bs-icon-small" ></i>
                                    </template>
                                    <template #default>
                                        <router-link to="/recruitment_manage_applicants" class="dropdown-item">
                                            <i class="bi bi-person-badge-fill bs-icon-small" ></i>
                                            <span> View further details </span>
                                        </router-link>
                                    </template>
                                </base-card-drop-down>
                            </td>
                        </tr>

                    </tbody>
                </table>

                <base-pagination>
                </base-pagination>

            </template>
    </base-card-plain>



</template>
