<template>
    <!-- title text and switch button -->
    <div class="text-center">
        <h1 class="mt-5">Pricing Plans</h1>
        <p class="mb-2 pb-75">
            All plans include 40+ advanced tools and features to boost your product. Choose the best plan to fit your needs.
        </p>
        <div class="d-flex align-items-center justify-content-center mb-5 pb-50">
            <h6 class="me-1 mb-0">Monthly</h6>
            <div class="form-check form-switch">
                <input type="checkbox" class="form-check-input" id="priceSwitch" />
                <label class="form-check-label" for="priceSwitch"></label>
            </div>
            <h6 class="ms-50 mb-0">Annually</h6>
        </div>
    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <div class="row pricing-card">
        <div class="col-12 col-sm-offset-2 col-sm-10 col-md-12 col-lg-offset-2 col-lg-10 mx-auto">
            <div class="row">
                <!-- basic plan -->
                <div class="col-12 col-md-4">
                    <div class="card basic-pricing text-center">
                        <div class="card-body">
                             <base-image
                                 imgName="Pot1.svg"
                                 imgGeo="illustration"
                                 imgAlt="svg img"
                                 imgClass="mb-2 mt-5"
                                 >
                             </base-image>
                            <h3>Basic</h3>
                            <p class="card-text">A simple start for everyone</p>
                            <div class="annual-plan">
                                <div class="plan-price mt-2">
                                    <sup class="font-medium-1 fw-bold text-primary">$</sup>
                                    <span class="pricing-basic-value fw-bolder text-primary">0</span>
                                    <sub class="pricing-duration text-body font-medium-1 fw-bold">/month</sub>
                                </div>
                                <small class="annual-pricing d-none text-muted"></small>
                            </div>
                            <ul class="list-group list-group-circle text-start">
                                <li class="list-group-item">100 responses a month</li>
                                <li class="list-group-item">Unlimited forms and surveys</li>
                                <li class="list-group-item">Unlimited fields</li>
                                <li class="list-group-item">Basic form creation tools</li>
                                <li class="list-group-item">Up to 2 subdomains</li>
                            </ul>
                            <button class="btn w-100 btn-outline-success mt-2">Your current plan</button>
                        </div>
                    </div>
                </div>
                <!--/ basic plan -->

                <!-- standard plan -->
                <div class="col-12 col-md-4">
                    <div class="card standard-pricing popular text-center">
                        <div class="card-body">
                            <div class="pricing-badge text-end">
                                <span class="badge rounded-pill badge-light-primary">Popular</span>
                            </div>
                            <base-image
                                imgName="Pot2.svg"
                                imgGeo="illustration"
                                imgAlt="svg img"
                                imgClass="mb-1"
                                >
                            </base-image>
                            <h3>Standard</h3>
                            <p class="card-text">For small to medium businesses</p>
                            <div class="annual-plan">
                                <div class="plan-price mt-2">
                                    <sup class="font-medium-1 fw-bold text-primary">$</sup>
                                    <span class="pricing-standard-value fw-bolder text-primary">49</span>
                                    <sub class="pricing-duration text-body font-medium-1 fw-bold">/month</sub>
                                </div>
                                <small class="annual-pricing d-none text-muted"></small>
                            </div>
                            <ul class="list-group list-group-circle text-start">
                                <li class="list-group-item">Unlimited responses</li>
                                <li class="list-group-item">Unlimited forms and surveys</li>
                                <li class="list-group-item">Instagram profile page</li>
                                <li class="list-group-item">Google Docs integration</li>
                                <li class="list-group-item">Custom “Thank you” page</li>
                            </ul>
                            <button class="btn w-100 btn-primary mt-2">Upgrade</button>
                        </div>
                    </div>
                </div>
                <!--/ standard plan -->

                <!-- enterprise plan -->
                <div class="col-12 col-md-4">
                    <div class="card enterprise-pricing text-center">
                        <div class="card-body">
                            <base-image
                                imgName="Pot3.svg"
                                imgGeo="illustration"
                                imgAlt="svg img"
                                imgClass="mb-2"
                                >
                            </base-image>
                            <h3>Enterprise</h3>
                            <p class="card-text">Solution for big organizations</p>
                            <div class="annual-plan">
                                <div class="plan-price mt-2">
                                    <sup class="font-medium-1 fw-bold text-primary">$</sup>
                                    <span class="pricing-enterprise-value fw-bolder text-primary">99</span>
                                    <sub class="pricing-duration text-body font-medium-1 fw-bold">/month</sub>
                                </div>
                                <small class="annual-pricing d-none text-muted"></small>
                            </div>
                            <ul class="list-group list-group-circle text-start">
                                <li class="list-group-item">PayPal payments</li>
                                <li class="list-group-item">Logic Jumps</li>
                                <li class="list-group-item">File upload with 5GB storage</li>
                                <li class="list-group-item">Custom domain support</li>
                                <li class="list-group-item">Stripe integration</li>
                            </ul>
                            <button class="btn w-100 btn-outline-primary mt-2">Upgrade</button>
                        </div>
                    </div>
                </div>
                <!--/ enterprise plan -->
            </div>
        </div>
    </div>
    <!--/ pricing plan cards -->

    <!-- pricing free trial -->
    <div class="pricing-free-trial">
        <div class="row">
            <div class="col-12 col-lg-10 col-lg-offset-3 mx-auto">
                <div class="pricing-trial-content d-flex justify-content-between">
                    <div class="text-center text-md-start mt-3">
                        <h3 class="text-primary">Still not convinced? Start with a 14-day FREE trial!</h3>
                        <h5>You will get full access to with all the features for 14 days.</h5>
                        <button class="btn btn-primary mt-2 mt-lg-3">Start 14-day FREE trial</button>
                    </div>

                    <!-- image -->
                    <base-image
                        imgName="pricing-Illustration.svg"
                        imgGeo="illustration"
                        imgAlt="svg img"
                        imgClass="pricing-trial-img img-fluid"
                        >
                    </base-image>
                </div>
            </div>
        </div>
    </div>
    <!--/ pricing free trial -->
</template>


<style scoped>
.pricing-card .card.popular {
  border: 1px solid #7367f0; }

.pricing-card .list-group-circle {
  font-weight: 500;
  color: #5e5873; }

.pricing-card .annual-plan {
  margin-bottom: 1.7rem; }

.pricing-card .annual-plan .plan-price sup {
  top: -1.5rem;
  left: 0.2rem; }

.pricing-card .annual-plan .plan-price span {
  font-size: 3.5rem;
  line-height: 0.8; }

.pricing-card .annual-plan .plan-price sub {
  bottom: 0;
  right: 0.14rem; }

.pricing-card .annual-plan .annual-pricing {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0; }

.pricing-free-trial {
  height: 16.71rem;
  background-color: rgba(186, 191, 199, 0.12);
  margin-right: -2rem;
  margin-left: -2rem;
  margin-top: 7.5rem;
  margin-bottom: 6.3rem; }

.pricing-free-trial .row {
  margin: 0; }

.pricing-free-trial .pricing-trial-content {
  padding: 0 1rem; }

.pricing-free-trial .pricing-trial-content img {
  position: relative;
  top: -3.1rem; }

@media (max-width: 767.98px) {
  .pricing-free-trial {
    height: auto; }
  .pricing-free-trial .pricing-trial-content {
    flex-direction: column;
    align-items: center; }
  .pricing-free-trial .pricing-trial-content .pricing-trial-img {
    top: 0;
    margin-top: 2rem;
    height: 150px; } }

@media (max-width: 575.98px) {
  .pricing-free-trial {
    margin-right: -1.2rem;
    margin-left: -1.2rem; } }


</style>
