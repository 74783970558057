<template>


    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper container-xxl p-0">
            <div class="content-header row">
                <div class="content-header-left col-md-9 col-12 mb-2">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h2 class="content-header-title float-start mb-0">Manage Subscriptions</h2>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">View subscription plans, history or add new subscription</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-body">
                <!-- account setting page -->
                <section id="pricing-plan">

                    <!-- subscriptions pricing plans -->
                    <subscriptions-pricing-plans>
                    </subscriptions-pricing-plans>
                    <!-- / subscriptions pricing plans -->



                    <!-- subscriptions history list -->
                    <subscriptions-history-list>
                    </subscriptions-history-list>
                    <!--/ subscriptions history list -->

                </section>
                <!-- / account setting page -->

            </div>
        </div>
    </div>
    <!-- END: Content-->


</template>

<script>
// import the core functions from vuex
import { useStore } from 'vuex';

import SubscriptionsPricingPlans from '@/components/subscriptions/SubscriptionsPricingPlans.vue';
import SubscriptionsHistoryList from '@/components/subscriptions/SubscriptionsHistoryList.vue';

export default{
    components:{
        SubscriptionsPricingPlans,
        SubscriptionsHistoryList
    },
    setup(){
        // get the global vuex store values
        const store = useStore();

        // use the dispatch function to call an action that calls a mutation
        store.dispatch("globals/executeSetNavMenu",true);
    }
}


</script>

<style scoped>
.pricing-card .card.popular {
  border: 1px solid #7367f0; }

.pricing-card .list-group-circle {
  font-weight: 500;
  color: #5e5873; }

.pricing-card .annual-plan {
  margin-bottom: 1.7rem; }

.pricing-card .annual-plan .plan-price sup {
  top: -1.5rem;
  left: 0.2rem; }

.pricing-card .annual-plan .plan-price span {
  font-size: 3.5rem;
  line-height: 0.8; }

.pricing-card .annual-plan .plan-price sub {
  bottom: 0;
  right: 0.14rem; }

.pricing-card .annual-plan .annual-pricing {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0; }

.pricing-free-trial {
  height: 16.71rem;
  background-color: rgba(186, 191, 199, 0.12);
  margin-right: -2rem;
  margin-left: -2rem;
  margin-top: 7.5rem;
  margin-bottom: 6.3rem; }

.pricing-free-trial .row {
  margin: 0; }

.pricing-free-trial .pricing-trial-content {
  padding: 0 1rem; }

.pricing-free-trial .pricing-trial-content img {
  position: relative;
  top: -3.1rem; }

@media (max-width: 767.98px) {
  .pricing-free-trial {
    height: auto; }
  .pricing-free-trial .pricing-trial-content {
    flex-direction: column;
    align-items: center; }
  .pricing-free-trial .pricing-trial-content .pricing-trial-img {
    top: 0;
    margin-top: 2rem;
    height: 150px; } }

@media (max-width: 575.98px) {
  .pricing-free-trial {
    margin-right: -1.2rem;
    margin-left: -1.2rem; } }


</style>
